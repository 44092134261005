(function(){
	angular.module('app.projects')
		.controller('projectDetailsCtrl',['$scope', '$http', '$window', '$uibModal',
			function($scope, $http, $window, $uibModal){

        $http.get($window.location.href)
          .success(function(response) {
            if (response.success) {
              if (response.gallery) {
                $scope.gallery = response.gallery;
              }
            }
        });

			  $scope.openGallery = function (imageId) {
          var modalInstance = $uibModal.open({
            templateUrl: '/modals/modal-project-gallery.tpl.html',
            controller: 'ModalInstanceCtrl',
            animation: true,
            scope: $scope,
            appendTo: $('body'),
            windowClass: 'modal-project-gallery',
            resolve: {
              gallery:  function () {
                return $scope.gallery;
              },
              imageId: function () {
                return imageId;
              }
            }
          });
        };

	}]);
}());
