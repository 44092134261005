/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('modules.forms')
    .component('formResults', {
      bindings: {
        formResult: '<'
      },
      template: '' +
        '<div ng-show="$ctrl.formResult.showLoader || $ctrl.formResult.showMessage" class="form-results-container">' +
            '<div ng-show="$ctrl.formResult.showLoader" class="form-results-loader text-center">' +
                '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>' +
                '<span class="sr-only">Loading...</span>' +
            '</div>' +
            '<h5 ng-show="$ctrl.formResult.showMessage" ng-bind-html="$ctrl.formResult.message"></h5>' +
        '</div>',
        controller: function() {}
    });
}());