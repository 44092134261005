(function(){
	angular.module('app.projects')
		.controller('projectsCtrl',['$scope', '$http', '$window', '$uibModal',
			function($scope, $http, $window, $uibModal){

        $http.get($window.location.href)
          .success(function(response) {
            if (response.success) {
              if (response.galleries) {
                $scope.galleries = response.galleries;
              }
            }
        });

			  $scope.openGallery = function (galleryId) {
			    $scope.gallery = _.find($scope.galleries, { 'project_id': galleryId });

          var modalInstance = $uibModal.open({
            templateUrl: '/modals/modal-project-gallery.tpl.html',
            controller: 'ModalInstanceCtrl',
            animation: true,
            scope: $scope,
            appendTo: $('body'),
            windowClass: 'modal-project-gallery',
            resolve: {
              gallery:  function () {
                return $scope.gallery;
              },
              imageId: function () {
                return -1;
              }
            }
          });
        };

	}])
	.controller('ModalInstanceCtrl', ['$scope', '$uibModalInstance', 'slickProtoService', 'gallery', 'imageId',
   function ($scope, $uibModalInstance, slickProtoService, gallery, imageId) {

    $scope.slickProjectGalleryConfig = new slickProtoService('white');
    $scope.slickProjectGalleryConfig.dots = false;
    $scope.slickProjectGalleryConfig.initialSlide = (imageId > -1) ? _.findIndex(gallery.assets, { 'id': imageId }) + (gallery.you_tube_url ? 1 : 0) : 0;

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  }]);
}());
