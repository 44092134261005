/*jslint white: true, nomen: true */
/*global angular */

(function() {
  'use strict';

  angular.module('app', [
    'app.givingBack',
    'app.common',
    'app.homepage',
    'app.styleguide',
    'app.forms',
    'app.news',
    'app.connectWithUs',
    'app.projects',
    'app.healthcareExpertise',
    'app.generalOffice',
    'app.privacyPolicy',
    'modules.forms',
    'ngSanitize',
    'ngAnimate',
    'slickCarousel',
    'angularResizable',
    'ngScrollbars',
    'ui.bootstrap',
    'htmlToPdfSave',
    'youtube-embed'
  ])
  .config(['$interpolateProvider', function($interpolateProvider) {
    $interpolateProvider.startSymbol('<%');
    $interpolateProvider.endSymbol('%>');
  }]);

  angular.module('app.common', []);
  angular.module('app.givingBack',[]);
  angular.module('app.homepage', []);
  angular.module('app.forms', []);
  angular.module('app.news', []);
  angular.module('app.connectWithUs', []);
  angular.module('app.projects', []);
  angular.module('app.healthcareExpertise',[]);
  angular.module('app.generalOffice',[]);
  angular.module('app.privacyPolicy',[]);
  angular.module('app.styleguide', []);

}());
