(function(){
	'use strict';
	angular.module('app.common')
	.directive('slickGroup', ['slickProtoService', '$timeout',
		function(slickProtoService, $timeout){
		return{
			restrict: 'E',
			template: '<slick id="<% main %>" class="main" settings="slickCfg">' +
						'<div class="item" ng-repeat="slide in slides track by $index">' + 
							'<img ng-src="<% slide %>" alt="img">' +
						'</div>' +
					'</slick>' +
					'<slick ng-if="showThumb" id="<% thumb %>" class="thumb" settings="slickThumb">' +
						'<div ng-repeat="slide in slides track by $index">' +
							'<div ng-style="{\'background-image\':\'url(<% slide %>)\'}"></div>' +
						'</div>' +
					'</slick>',
			scope: {
				slides : '=',
				id : '@'
			},
			link: function(scope, elem){

				var count = scope.slides.length;

				scope.main = 'main-' + scope.id;
				scope.thumb = 'thumb-' + scope.id;

				//Responsive
				var breakPoints = [
					{width: 1450, count: 5}, 
					{width: 1230, count: 4}, 
					{width: 991, count: 3}
				];

				var slickResponsive = [];

				setSlickResponsive();

				//Main Slide
				var slickSettings = new slickProtoService('white');
				slickSettings.dots = false;

				//THumbnail slide
				var smSlickSettings = new slickProtoService('white');
				smSlickSettings.slidesToShow = 7;
				smSlickSettings.dots = false;
				smSlickSettings.arrows = false;
				smSlickSettings.focusOnSelect = true;
				smSlickSettings.responsive = slickResponsive;

				if(count > 1){
					scope.showThumb = true;				
					slickSettings.asNavFor = '#' + scope.thumb;
					smSlickSettings.asNavFor = '#' + scope.main;
				}

				scope.slickCfg = slickSettings;
				scope.slickThumb = smSlickSettings;

				$(window).on('load resize', function(){
					slickClassOverride();
				});

				//function

				function setSlickResponsive(){
					for(var i = 0; i < breakPoints.length; i++){
						var transform = {
							breakpoint: breakPoints[i].width,
							settings: {slidesToShow : breakPoints[i].count}
						};

						slickResponsive.push(transform);
					}
				}

				function slickClassOverride(){
					var thumb = $('#'+scope.thumb);
					var slides = thumb.find('.slick-slide:visible');
					var winWidth = $(window).width();

					console.log("this: " + breakPoints[0].width);
					//goal: set slide to center if the slides visible is less than slick slidesToShow property

					if(winWidth > breakPoints[0].width && slides.length <= smSlickSettings.slidesToShow){
						addThumbClass(thumb);
					}else{
						for(var i = 0; i < breakPoints.length; i++){
							var br = breakPoints[i];
							thumb.removeClass('slide-center');
							if(winWidth < breakPoints[i].width && slides.length <= br.count){
								addThumbClass(thumb);
							}
						}
					}
				}

				function addThumbClass(elem){
					$timeout(function(){
						elem.addClass('slide-center');
					}, 100);
				}

			}
		}
	}])
}());