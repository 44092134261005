(function(){
	'use strict';
	angular.module('app.common')
	.directive('smTrigger', ['$timeout', function($timeout){
		return {
			restrict: 'EA',
			scope: {
				//optional
				target: '@', // target element to change
				offset: '@', // offset of current element
				hook: '@', // placement of triggerhook (default, onEnter, onLeave)
				pad: '@', //pad the difference when leaving area (set value to 'height' to reference window height)
				invert: '@', //invert class toggle
				debug: '@', //show indicator
				
				//required
				className: '@' // name of class to toggle
			},
			link: function(scope, elem, attrs){

				//define properties
				var settings = {				
					h : parseInt(elem.height()),
					controller : new ScrollMagic.Controller(),
					target : elem,
					offset : $('header > nav').outerHeight() + 70,
					hook : "onCenter",
					indicator : ['green','red']
				};

				
				//check if the following are defined
				if(scope.target){
					settings.target = scope.target;
				}

				if(scope.offset){
					settings.offset = scope.offset;
					//convert percentage
					if(settings.offset.indexOf('%') > 0){
						var dec = parseInt(scope.offset) * .01;
						settings.offset = settings.h * dec;
					}
				}

				if(scope.pad){
					settings.h = settings.h * 1.75;
					if(scope.pad == 'height'){
						var maxH = $(window).height();
						if(maxH > 1200){
							maxH = 1200;
						}
						settings.h = maxH - (settings.offset * -2);
					}
				}

				if(scope.hook){
					settings.hook = scope.hook;
					//change color of indicator for visual aid
					if(settings.hook == 'onEnter'){
						settings.indicator = ['orange','teal'];
					}
					if(settings.hook == 'onLeave'){
						settings.indicator = ['violet','purple']
					}
				}

				setTimeout(function(){
					//initiate scene and each element must have an ID
					var smScene = new ScrollMagic.Scene({
							triggerHook: settings.hook,
							triggerElement: '#' + attrs.id,
							duration: settings.h,
							offset: -settings.offset
						});

					smScene.on("enter leave", function (e) {
						var dir = "enter";

						if(scope.invert){
							dir = "leave";
						}

						if(e.type == dir){
							$(settings.target).addClass(scope.className);
						}else{
							$(settings.target).removeClass(scope.className);
						}

					})
					.addTo(settings.controller);

					if(scope.debug){
						smScene.addIndicators({
							name:attrs.id,
							colorStart: settings.indicator[0],
							colorEnd: settings.indicator[1]
						});
					}
				},500);

			}
		}
	}]);
}());