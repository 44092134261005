/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular
    .module('app.forms')
    .component('formFooterContact', {
      bindings: {},
      templateUrl: '/contact-us/templates/form-footer',
      controller: ['$window', 'FormService',
        function($window, FormService) {

          var ctrl = this;

          this.$postLink = function() {
            var prefillMode = false;
            var formName    = 'footerContactForm';
            var prefillData = FormService.prefillForm(prefillMode, formName);

            this.formData   = angular.copy(prefillData.formData);
            this.formResult = angular.copy(prefillData.formResult);
            this.compConfig = FormService.getComponentConfig('noPlaceholders', this[formName]);
          };

          this.updateFormData = function(key, value) {
            this.formData[key] = value;
          };

          this.validateForm = function(form) {
            if (! form.$valid) {
              return;
            }

            this.formResult.showForm = false;
            this.formResult.showLoader = true;

            FormService
              .submit('/forms/contact-us', this.formData)
              .then(this.handleSubmitResults);
          };

          this.handleSubmitResults = function(result) {
            ctrl.formResult = result;
          };

        }]
    });

})();
