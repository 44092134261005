(function(){
	'use strict';
	angular.module('app.common')
	.controller('menuCtrl', [
		'$scope', 
		'DeviceService',
		function($scope, DeviceService){

	        //IE detect
	        if (navigator.userAgent.match(/Trident\/7\./)) {
	          $('html').addClass('is-ie');
	        }

	        //Iphone only
	        if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
	        	navigator.userAgent.toLowerCase().indexOf("ios") > -1) {
	          $('html').addClass('is-ios');
	        }

	        //safari only
	        if (navigator.userAgent.toLowerCase().indexOf("safari") > -1 &&
	        	navigator.userAgent.toLowerCase().indexOf("chrome") == -1 &&
	        	navigator.userAgent.toLowerCase().indexOf("iphone") == -1) {
	          $('html').addClass('is-safari');
	        }
        
			$(window).on('load resize', function(){
				classToggle('html','is-touch',true);
			})

			function classToggle(target, name){

				$(target).removeClass(name);
				
				if(DeviceService.touch() && $(window).width() < 1231){
					$(target).addClass(name);
				}
			}

			$scope.mobile = 'close';

			$scope.clickthrough = function(url){
				if(!DeviceService.touch()){
					window.location.href = url;
				}
			}

			$scope.mobileMenu = function(url){
				if(DeviceService.touch()){
					var link = ($scope.mobile == url) ? 'close' : url;
					$scope.mobile = link;
				}
			}
	}]);
}());