(function(){
	'use strict';
	angular.module('app.common')
	.directive('scrollTo', function(){
		return{
			restrict: 'EA',
			scope: {
				scrollTo: '@',
				onInit: '@'
			},
			link: function(scope, elem){
				var page = $("html, body");

				elem.on('click', function(){
					scroll();
				})

				if(scope.onInit){
					scroll();
				}

				function scroll(){
					var top = $(scope.scrollTo).offset().top;

					page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function(){
						page.stop();
					});

					page.animate({ scrollTop: top }, 600, 'swing', function(){
						page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
					});

				   return false; 
				}

			}
		}
	})
}());