(function(){
	'use strict';

	angular.module('app.common')
	.directive('smParallax',function(){
		return {
			restrict: 'EA',
			link: function(scope, elem, attrs){
				var id = '#' + attrs.id;
				var target = id + ' ' + attrs.smParallax;

				var controller = new ScrollMagic.Controller();

				//must define ID

				new ScrollMagic.Scene({
						triggerElement: id,
						triggerHook: "onEnter", 
						duration: "400%"
					})
					.setTween(target, {
						y: "100%", 
						ease: Linear.easeNone
					})
					// .addIndicators({
					// 	name: attrs.id,
					// 	colorStart: 'pink',
					// 	colorEnd: 'magenta',
					// 	colorTrigger: 'gold'
					// })
					.addTo(controller);

				var offset = $(target).offset().top;
				$(target).css('top',-offset - 9);
			}
		}
	});
}());