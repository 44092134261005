/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('modules.forms')
    .component('formComponentDivSelect', {
      bindings: {
        config: '<compConfig',
        hideLabel: '<compHideLabel',
        label: '@compLabel',
        options: '=compOptions',
        placeholder: '@compPlaceholder',
        required: '<compRequired',
        model: "=compModel",
        onUpdate: '&'
      },
      template: '' +
          '<label ng-if="! $ctrl.hideLabel" for="<% $ctrl.id %>" ng-class="{ required: $ctrl.required, error: $ctrl.error }">' +
              '<% $ctrl.label %>' +
              '<span ng-if="$ctrl.required" class="required">*</span>' +
          '</label>' +
          '<div class="form-component">' +
            '<div class="dropdown form-dropdown">' +
              '<button class="btn dropdown-toggle" '+
              'type="button"' +
              'ng-attr-id="<% $ctrl.id %>"' +
              'data-toggle="dropdown"' +
              'aria-haspopup="true"' +
              'aria-expanded="true"' +
              '>' +
                '<span class="text"><% $ctrl.placeholder %></span>' +
                '<span class="arrow"></span>' +
              '</button>' +

              '<ul class="dropdown-menu" aria-labelledby="">' +
                '<li ng-click="$ctrl.onUpdate({value: option.id}) || $ctrl.select(option)"' +
                     'ng-repeat="option in $ctrl.options track by option.id"' +
                '>' +
                '<a><% option.id %></a>' +
              '</ul>' +
            '</div>' + 
          '</div>',

      controller: ['FormComponentService', function(FormComponentService) {

        this.error = false;

        this.$onChanges = function(changes) {
          if (changes.config && changes.config.isFirstChange()) {
            return;
          }

          this.initComponent();
        };

        this.$doCheck = function() {
          if (this.config && this.config.formElement.$submitted) {
            this.error = ! this.isValid();
          }
        };

        this.initComponent = function() {
          this.id          = FormComponentService.spinalCase(this.config.prefix + '_' + this.label);
          this.name        = FormComponentService.snakeCase(this.label);
          this.type        = FormComponentService.defineAttr(this.type, 'text');
          this.required    = FormComponentService.defineAttr(this.required, true);
          this.hideLabel   = FormComponentService.defineAttr(this.hideLabel, false);
          this.init        = true;
          this.setPlaceholder();
        };

        this.setPlaceholder = function() {
          if (typeof this.placeholder === 'undefined') {
            this.placeholder = FormComponentService.getPlaceholder(
              this.config.textPlaceholders,
              this.label,
              this.required
            );
          }
        };

        this.select = function(option) {
          this.placeholder = option.name;
          this.model = option.id;
        };

        this.isValid = function() {
          if(!this.required) {
            return true;
          }

          return !(typeof this.model === 'undefined');
        };

      }]
    });

})();