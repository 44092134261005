(function() {
  'use strict';
  /* global, angular */
  angular
    .module("modules.forms")

    .service("FormComponentsOptionsService", [
      function() {
        var service = {};

        service.getStates = function() {
          return [
            {
              "name": "Alabama",
              "id": "AL"
            }, {
              "name": "Alaska",
              "id": "AK"
            }, {
              "name": "Arizona",
              "id": "AZ"
            }, {
              "name": "Arkansas",
              "id": "AR"
            }, {
              "name": "California",
              "id": "CA"
            }, {
              "name": "Colorado",
              "id": "CO"
            }, {
              "name": "Connecticut",
              "id": "CT"
            }, {
              "name": "Delaware",
              "id": "DE"
            }, {
              "name": "Dist. of Columbia",
              "id": "DC"
            }, {
              "name": "Florida",
              "id": "FL"
            }, {
              "name": "Georgia",
              "id": "GA"
            }, {
              "name": "Hawaii",
              "id": "HI"
            }, {
              "name": "Idaho",
              "id": "ID"
            }, {
              "name": "Illinois",
              "id": "IL"
            }, {
              "name": "Indiana",
              "id": "IN"
            }, {
              "name": "Iowa",
              "id": "IA"
            }, {
              "name": "Kansas",
              "id": "KS"
            }, {
              "name": "Kentucky",
              "id": "KY"
            }, {
              "name": "Louisiana",
              "id": "LA"
            }, {
              "name": "Maine",
              "id": "ME"
            }, {
              "name": "Maryland",
              "id": "MD"
            }, {
              "name": "Massachusetts",
              "id": "MA"
            }, {
              "name": "Michigan",
              "id": "MI"
            }, {
              "name": "Minnesota",
              "id": "MN"
            }, {
              "name": "Mississippi",
              "id": "MS"
            }, {
              "name": "Missouri",
              "id": "MO"
            }, {
              "name": "Montana",
              "id": "MT"
            }, {
              "name": "Nebraska",
              "id": "NE"
            }, {
              "name": "Nevada",
              "id": "NV"
            }, {
              "name": "New Hampshire",
              "id": "NH"
            }, {
              "name": "New Jersey",
              "id": "NJ"
            }, {
              "name": "New Mexico",
              "id": "NM"
            }, {
              "name": "New York",
              "id": "NY"
            }, {
              "name": "North Carolina",
              "id": "NC"
            }, {
              "name": "North Dakota",
              "id": "ND"
            }, {
              "name": "Ohio",
              "id": "OH"
            }, {
              "name": "Oklahoma",
              "id": "OK"
            }, {
              "name": "Oregon",
              "id": "OR"
            }, {
              "name": "Pennsylvania",
              "id": "PA"
            }, {
              "name": "Rhode Island",
              "id": "RI"
            }, {
              "name": "South Carolina",
              "id": "SC"
            }, {
              "name": "South Dakota",
              "id": "SD"
            }, {
              "name": "Tennessee",
              "id": "TN"
            }, {
              "name": "Texas",
              "id": "TX"
            }, {
              "name": "Utah",
              "id": "UT"
            }, {
              "name": "Vermont",
              "id": "VT"
            }, {
              "name": "Virginia",
              "id": "VA"
            }, {
              "name": "Washington",
              "id": "WA"
            }, {
              "name": "West Virginia",
              "id": "WV"
            }, {
              "name": "Wisconsin",
              "id": "WI"
            }, {
              "name": "Wyoming",
              "id": "WY"
            }
          ];
        };

        service.getBooelans = function() {
          return [
            {
              "name": "Yes",
              "id": 1
            },
            {
              "name": "No",
              "value": 0
            }
          ];
        };

        service.getReferrals = function() {
          return [
            {
              name: "Billboard",
              id: "Billboard"
            },
            {
              name: "Phone Call",
              id: "Phone Call"
            },
            {
              name: "Direct Mail",
              id: "Direct Mail"
            },
            {
              name: "Facebook",
              id: "Facebook"
            },
            {
              name: "Internet Advertisment",
              id: "Internet Advertisment"
            },
            {
              name: "MySouthernHome.com",
              id: "MySouthernHome.com"
            },
            {
              name: "The Ledger",
              id: "The Ledger"
            },
            {
              name: "Eyes and Ears",
              id: "Eyes and Ears"
            },
            {
              name: "The Lakelander",
              id: "The Lakelander"
            },
            {
              name: "Parade of Homes",
              id: "Parade of Homes"
            },
            {
              name: "A Friend or Family Member",
              id: "A Friend or Family Member"
            },
            {
              name: "A Realtor",
              id: "A Realtor"
            }
          ];
        };

        service.getTimeframes = function() {
          return [
            {
              "name": "30 days",
              "id": "30 days"
            },
            {
              "name": "60-90 days",
              "id": "60-90 days"
            },
            {
              "name": "More Than 90 days",
              "id": "More Than 90 days"
            }
          ];
        };

        service.getDesiredLocations = function() {
          return [
            {
              id: "auburndale",
              label: "Auburndale"
            },
            {
              id: "lake-wales",
              label: "Lake Wales"
            },
            {
              id: "winter-haven",
              label: "Winter Haven"
            },
            {
              id: "bartow",
              label: "Bartow"
            },
            {
              id: "north-lakeland",
              label: "North Lakeland"
            },
            {
              id: "on-your-land",
              label: "On Your Land"
            },
            {
              id: "haines-city",
              label: "Haines City"
            },
            {
              id: "south-lakeland",
              label: "South Lakeland"
            }
          ];
        };

        service.getHomeTypes = function() {
          return [
            {
              id: "split-one-story",
              label: "Split-One-story"
            },
            {
              id: "two-story",
              label: "Two-story"
            },
            {
              id: "great-room",
              label: "Great Room"
            },
            {
              id: "dining-room",
              label: "w/ Dining Room"
            },
            {
              id: "living-room",
              label: "w/ Living Room"
            },
            {
              id: "lanai",
              label: "w/ Lanai"
            },
            {
              id: "1400-1800-sqft",
              label: "1,400 - 1,800 Sq. Ft."
            },
            {
              id: "1800-2200-sqft",
              label: "1,800 - 2,200 Sq. Ft."
            },
            {
              id: "2200-2600-sqft",
              label: "2,200 - 2,600 Sq. Ft."
            },
            {
              id: "2600-3000-sqft",
              label: "2,600 - 3,000 Sq. Ft."
            },
            {
              id: "above-3000-sqft",
              label: "Above 3,000 Sq. Ft."
            },
            {
              id: "3-bed-2-bath",
              label: "3 Bed, 2 Bath"
            },
            {
              id: "5-bed-3-bath",
              label: "5 bed, 3 bath"
            },
            {
              id: "3-bed-3-bath",
              label: "3 Bed, 3 Bath"
            },
            {
              id: "4-bed-2-bath",
              label: "4 Bed, 2 Bath"
            },
            {
              id: "4-bed-3-bath",
              label: "4 Bed, 3 Bath"
            }
          ];
        };

        service.getPriceRanges = function() {
          return [
            {
              id: "$100,000 - $150,000",
              name: "$100,000 - $150,000"
            },
            {
              id: "$150,000 - $200,000",
              name: "$150,000 - $200,000"
            },
            {
              id: "$200,000 - $250,000",
              name: "$200,000 - $250,000"
            },
            {
              id: "$250,000 - $300,000",
              name: "$250,000 - $300,000"
            },
            {
              id: "$300,000 - $350,000",
              name: "$300,000 - $350,000"
            },
            {
              id: "$350,000 - $400,000",
              name: "$350,000 - $400,000"
            }
          ];
        };

        service.getSalesSpecialists = function() {
          return [
            {
              id: "John Doe",
              name: "John Doe"
            },
            {
              id: "Jill Doe",
              name: "Jill Doe"
            },
            {
              id: "Bill Smith",
              name: "Bill Smith"
            }
          ];
        };

        return service;
      }]);
}());