(function(){
	angular.module('app.healthcareExpertise')
		.controller('healthcareExpertiseCtrl',['$scope', 'slickProtoService',
			function($scope, slickProtoService){

        $scope.slickConfig = new slickProtoService('white');
        $scope.slickConfig.autoplay = true;
        $scope.slickConfig.autoplaySpeed = 7000;
        $scope.slickConfig.dots = false;

        $scope.portfolios = [
          {
            title: 'Stanford Castro Valley',
            url: '/portfolio/castro-valley-II-ca',
            image: '/system/App/ContentField/resources/000/002/870/large/castro-valley-II-header.jpg'
          },
          {
            title: 'Torrance Medical Office Building',
            url: '/portfolio/torrance-ca',
            image: '/system/App/ContentField/resources/000/002/238/large/Torrance_MAIN-PICTURE.jpg'
          },
          {
            title: 'Mountain View Medical Office Building',
            url: '/portfolio/mountain-view-ca',
            image: '/system/App/ContentField/resources/000/000/566/large/Oct-15-Meridian_Mountain_View5.jpg'
          },
          {
            title: 'San Rafael Outpatient Clinic',
            url: '/projects/san-rafael-outpatient-clinic',
            image: '/system/App/ContentField/resources/000/000/583/large/Jul-15-Meridian%20San%20Rafael-091-HDR.jpg'
          },
          {
            title: 'Santa Ana Medical Office Building',
            url: '/portfolio/santa-ana-ca',
            image: '/system/App/ContentField/resources/000/002/774/large/santa-ana.jpg'
          },
          {
            title: 'Modesto Outpatient Dialysis Clinic',
            url: '/portfolio/modesto-2-ca',
            image: '/system/App/ContentField/resources/000/002/266/large/Modesto_MAIN-PICTURE.jpg'
          },
          {
            title: 'Cotton Medical Center',
            url: '/projects/pasadena-ca',
            image: '/system/App/ContentField/resources/000/000/715/large/CF014735.jpg'
          }
        ];

	}]);
}());
