/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('modules.forms')
    .component('formComponentEmail', {
      bindings: {
        config: '<compConfig',
        hideLabel: '<compHideLabel',
        label: '@compLabel',
        labelClass: '@compLabelClass',
        placeholder: '@compPlaceholder',
        required: '<compRequired',
        type: '@compType',
        onUpdate: '&'
      },
      template: '' +
          '<label ng-if="! $ctrl.hideLabel" for="<% $ctrl.id %>"' +
            'ng-class="{ required: $ctrl.required, error: $ctrl.error }"' +
            'class="<% $ctrl.labelClass %>"' +
          '>' +
              '<% $ctrl.label %>' +
              '<span ng-if="$ctrl.required" class="required">*</span>' +
          '</label>' +
          '<input ng-attr-type="<% $ctrl.type %>"' +
              'ng-attr-id="<% $ctrl.id %>"' +
              'ng-attr-name="<% $ctrl.name %>"' +
              'class="form-control"' +
              'ng-class="{required: $ctrl.required}"' +
              'ng-attr-placeholder="<% $ctrl.placeholder %>"' +
              'ng-model="$ctrl.value"' +
              ' overwrite-email ' +
              'ng-change="$ctrl.onUpdate({value: $ctrl.value})"' +
              'ng-required="$ctrl.required"' +
          '/>',
      controller: ['FormComponentService', function(FormComponentService) {

        this.error = false;

        this.$onChanges = function(changes) {
          if (changes.config && changes.config.isFirstChange()) {
            return;
          }

          this.initComponent();
        };

        this.$doCheck = function() {
          if (this.config && this.config.formElement.$submitted) {
            this.error = ! isValid(this.config.formElement[this.name].$error);
          }
        };

        this.initComponent = function() {
          this.id          = FormComponentService.spinalCase(this.config.prefix + '_' + this.label);
          this.name        = FormComponentService.snakeCase(this.label);
          this.type        = FormComponentService.defineAttr(this.type, 'text');
          this.required    = FormComponentService.defineAttr(this.required, true);
          this.hideLabel   = FormComponentService.defineAttr(this.hideLabel, false);
          this.init        = true;
          this.setPlaceholder();
        };

        this.setPlaceholder = function() {
          if (typeof this.placeholder === 'undefined') {

            this.placeholder = FormComponentService.getPlaceholder(
              this.config.textPlaceholders,
              this.label,
              this.required
            );

          }
        };

        var isValid = function(obj) {
          if (obj.hasOwnProperty('required')) {
            return false;
          }

          return true;
        };

      }]
    });

})();