(function(){
	'use strict';

	angular.module('app.common')
	.directive('toggleClass', ['DeviceService', function(DeviceService){
		return{
			restrict: 'A',
			scope: {
				classname : '@',
				target: '@'
			},
			link: function(scope,elem){
				elem.find(scope.target).on('mouseenter',function(){
					$(this).addClass(scope.classname);
				});
				elem.find(scope.target).on('mouseleave',function(){
					$(this).removeClass(scope.classname);
				});
			}
		}
	}])
}());