(function() {
  'use strict';

  angular.module('app.common')
    .component('ellipse', {
      template: '<span ng-bind-html="$ctrl.output"></span>',
      transclude: true,
      controller: ['$rootScope', ellipse],
      bindings: {
        copy: '@',
        max: '@',
      }
    });

  function ellipse($rootScope) {
    var vm = this;
    var max = vm.copy.length;

    if (vm.copy.length > vm.max) {
      var truncate = vm.copy.substr(0, vm.max);
      truncate = truncate.substr(0, Math.min(truncate.length, truncate.lastIndexOf(" ")));
      vm.output = truncate + '...';
    } else {
      vm.output = vm.copy;
    }
  }

}());