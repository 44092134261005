(function(){
	'use strict';

	angular.module('app.common')
	.directive('printToPdf', ['$timeout', function($timeout){
		return{
			restrict: 'A',
			scope: {
				cloneFrom : '@',
				cloneTo: '@'
			},
			link: function(scope,elem){

				//hides button if less than 1220
				$(window).on('load resize', function(){
					if($(window).width() < 1220){
						elem.addClass('hidden');
					}else{
						elem.removeCLass('hidden');
					}
				})

				//clone content
				$timeout(function(){
					var cloned = $(scope.cloneFrom).html();
					$(scope.cloneTo).html(cloned);					
				}, 500);

				
				elem.on('click', function(){

					$(elem).after('<span class="print-msg"> NOW PRINTING....</span>');
					elem.hide();

					var pos = 770;
					var height = $('#pdf-content').height();
					var count = Math.ceil(height / pos);

					var cycle = 0;
					var array = [];

					for(var i = 0; i < count; i++){

						//shift image
						$('#pdf-content').children().css('top',(-pos * i)+'px');

						//render
						html2canvas($('#pdf'), {					 
							  onrendered: function(canvas) {

							  	var img = canvas.toDataURL("image/png",1);

							  	array.push({
							  		image: img,
							  		width: 615
							  	});
							  	
								cycle++;

								if(cycle == count){

									var docDefinition = {
									  pageSize: 'LETTER',
									  pageOrientation: 'portrait',
									  pageMargins: [ 0, 0 ],
									  content: array,
									};
									
									pdfMake.createPdf(docDefinition).open();
								}
							  }
						});
					}

					//reset image
					$('#pdf-content').children().css('top','0px');

					$timeout(function(){
						$('.print-msg').remove();
						elem.show();
					}, 4000);
				});

			}
		}
	}])
}());