(function(){
	angular.module('app.givingBack')
		.controller('givingBackCtrl',['$scope', '$uibModal',
			function($scope, $uibModal){

			$scope.sponsors = [
			 {
          id: 'food-bank-of-alameda-county',
          logo: '/images/site/giving-back/logo-food-bank.png',
          title: 'Food Bank of Alameda County',
          details: '<p>Each holiday season, amid the year end dash to get projects completed and prep for the New Year, Meridian takes a break to volunteer at a local Food Bank.  For the Holiday season, NorCal employees work at a local food bank, such as Contra Costa Food Bank or the Alameda County Food Bank. While, our SoCal employees volunteer at the Orange County Food Bank.</p><p>Alameda County Community Food Bank has been in business since 1985 ... with a vision toward a day when we can go out of business. They are the hub of a vast collection and distribution network that provides food for 240 nonprofit agencies in Alameda County. In 2015, the Food Bank distributed 25 million meals -- more than half of the food was fresh fruits and vegetables.</p><p>For information on volunteering or donating to the Food Bank, please visit <a href="http://www.accfb.org" target="_blank">www.accfb.org</a>.</p>',
          slides: [
            '/images/site/giving-back/food-bank5.jpg'
          ]
        },
        {
          id: 'special-olympics-arizona',
          logo: '/images/site/giving-back/logo-special-olympics-arizona.png',
          title: 'Special Olympics Arizona',
          details: '<p>Meridian has shown its support for the Special Olympics byparticipating in Over the Edge for Special Olympics, Arizona. Team members rappel down 27 stories of the CityScape building in downtown Phoenix to honor the brave Special Olympics athletes who face unique challenges and overcome adversities on a daily basis.</p><p>For more information on Special Olympics, Arizona, please visit <a href="https://specialolympicsarizona.org/" target="_blank">Special Olympics Of Arizona | Explore Capabilities | Home (specialolympicsarizona.org)</a></p>',
          slides: [
            '/images/site/giving-back/special-olympics-arizona-1.jpg'
          ]
        },
				{
				  id: 'proud-sponsor-of-tour-davita',
					logo: '/images/site/giving-back/logo-davita.png',
					title: 'Proud Sponsor <br/>of Tour DaVita',
					details: '<p>Meridian has participated in Tour DaVita for over 7 years as sponsors and as avid riders. Meridian riders train hard all season to prepare for the ride of a lifetime and complete over 250 miles each over the course of the three-day ride. The experience is one they have called unforgettable and most importantly, served a meaningful cause.</p><p>Tour DaVita is a creative, adventurous, and healthy way to heighten Kidney Disease awareness, and to raise money for DaVita Village Trust. DaVita Village Trust’s goal is to improve community health, wellness, and vitality through providing chronic kidney disease education, prevention, and treatment to patients around the world. The three-day bicycle ride is held in a different part of the country each year, and provides both riders and volunteers an incredible and truly unforgettable experience.</p><p>For more information on Tour DaVita, please visit <a href="http://www.tourdavita.org" target="_blank">www.tourdavita.org</a>.</p>',
					slides: [
						'/images/site/giving-back/tour-davita-2019-1.jpg',
						'/images/site/giving-back/tour-davita-2019-2.jpg',
						'/images/site/giving-back/tour-davita-2019-3.jpg',
						'/images/site/giving-back/tour-davita-2019-4.jpg'
					]
				}
			];

      $scope.openGallery = function (sponsorId) {
          $scope.gallery = _.find($scope.sponsors, { 'id': sponsorId });

          var modalInstance = $uibModal.open({
            templateUrl: '/modals/modal-sponsor-gallery.tpl.html',
            controller: 'ModalInstanceCtrl',
            animation: true,
            scope: $scope,
            appendTo: $('body'),
            windowClass: 'modal-project-gallery',
            resolve: {
              gallery:  function () {
                return $scope.gallery;
              },
              imageId: function () {
                return -1;
              }
            }
          });
        };

	}]);
}());
