(function() {
  "use strict";

  angular
    .module("modules.analytics", [])
    .config(['AnalyticsServiceProvider', function(AnalyticsServiceProvider) {

      var config = {
        clicky: {
          enabled: false
        },
        googleAnalytics: {
          enabled: true,
          key: 'UA-22950669-1'
        }
      };

      AnalyticsServiceProvider.setConfig(config);

    }])
  ;

})();