(function(){
	angular.module('app.homepage')
	.controller('homepageCtrl',[
		'$scope',
		'slickProtoService', 
		'DeviceService',
		'$timeout',
		function(
			$scope, 
			slickProtoService,
			DeviceService,
			$timeout){

		$scope.device = 'desktop';
		if(DeviceService.check() == 'phone'){
			$scope.device = 'mobile'
		}

		//HERO SLIDESHOW
		var slideVideo = document.getElementById("video-slide"),
		slideVideoThree = document.getElementById("video-slide-three");

		$scope.video = 'https://www.youtube.com/embed/hz7KP7Lv524';
		$scope.videoThree = 'https://www.youtube.com/embed/k7DD8v-Q-Ek';
		$scope.playerVars = {
      controls: 0,
      autoplay: 0,
      loop: 1,
      mute: 1,
      rel: 0
    };

    $scope.$on('youtube.player.ready', function ($event, player) {
      $scope.player.playVideo();
	  $scope.playerThree.playVideo();
      TweenMax.to(slideVideo,0.2,{opacity: 1});
	  TweenMax.to(slideVideoThree,0.2,{opacity: 1});
    });

		$scope.slickConfig = new slickProtoService('white');
		$scope.slickConfig.autoplay = true;
		$scope.slickConfig.autoplaySpeed = 5000;
	    $scope.slickConfig.event = {
	        beforeChange: function (event, slick, currentSlide, nextSlide) {
	        	TweenMax.to(slideVideo,0.2,{opacity:0});
			TweenMax.to(slideVideoThree,0.2,{opacity:0});
	        },
	        afterChange: function (event, slick, currentSlide, nextSlide) {
	        	$scope.accordianSlide = "";
            $('slick .time-lapse').hasClass('slick-active')? $scope.player.playVideo() : $scope.player.pauseVideo();
			$('slick .time-lapse-three').hasClass('slick-active')? $scope.playerThree.playVideo() : $scope.playerThree.pauseVideo();
	        	TweenMax.to(slideVideo,0.2,{opacity: 1});
			TweenMax.to(slideVideoThree,0.2,{opacity: 1});
	        }
	    }

		$scope.toggleAccordian = function(slide){
			if(slide == $scope.accordianSlide){
				$scope.accordianSlide = "";
			}else{
				$scope.accordianSlide = slide;
			}
		}

	    //NEWS SLIDESHOW
		$scope.slickNewsConfig = new slickProtoService('blue');
		$scope.slickNewsConfig.slidesToShow = 3;
		$scope.slickNewsConfig.slidesToScroll = 3;
		$scope.slickNewsConfig.responsive = [{
			breakpoint: 991,
			settings: {
				slidesToShow : 2,
				slidesToScroll : 2
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow : 1,
				slidesToScroll : 1
			}
		}]

	}])
}());