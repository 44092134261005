(function(){
	'use strict';
	angular.module('app.styleguide')
	.controller('styleguideCtrl',[
		'$scope',
		'$http',
		'getDataService', 
		function($scope, $http, getDataService){


		getDataService.variables().then(function(result){
			$scope.swatches = result;
			$scope.colorClass = $scope.swatches[0].color.replace("$","");
			$scope.color = $scope.swatches[0].hex;
		})

		getDataService.svgFiles().then(function(result){
			$scope.svgFiles = result;
		})


		$scope.fontWeights = ['200','300','400','500','600','700'];

		$scope.swapColor = function(swatch){
			$scope.color = swatch.hex;
			$scope.colorClass = swatch.color.replace('$','');
		}

		$scope.copyToClipBoard = function(e){
			var inp = $(e.target).find('input');

			if (inp && inp.select) {
				inp.select();
				document.execCommand('copy');
				inp.blur();
			}
		};

		$scope.padding = true;

		$scope.toggle = function(elem){
			$(elem).toggleClass('hidden');

			if($('header').hasClass('hidden')){
				$scope.padding = false;
			}else{
				$scope.padding = true;
			}
		}

		$scope.closeCloneWindow = function(){
			$('.cloned-window').removeClass('open');
		}

		$scope.cloneItem = function(){
			$('.clone-item:eq(0)').clone().insertBefore('.cloned-window hr');
		}

	}])
	.service('getDataService',['$http','$q', function($http, $q){

		this.variables = function(){
	  		return $http.get('/js/styleguide/variables.json')
	  		.then(function(res){
				var swatches = [];
		        angular.forEach(res.data, function(hx, col){
		        	swatches.push({
		        		color : '$'+col,
		        		hex : hx
		        	});
		        });
	        	return swatches;
	        });

		};

		this.svgFiles = function(){
	  		return $http.get('/images/site/global/filelist.json')
	  		.then(function(response){
				var svgs = [];
		        angular.forEach(response.data, function(file){
		        	var isSvg = file.indexOf('.svg');
		        	if(isSvg > 0){
		        		var file = file.replace('public','');
		        		var fileName = file.replace('/images/site/global/','');
		        		svgs.push({
		        			path: file,
		        			name: fileName
		        		});
		        	}
		        });
	        	return svgs;
	        });

		};

	}])
	.directive('resizeWrap',['$rootScope', function($rootScope){
		return{
			restrict: 'E',
			transclude: true,
			scope: {
				title: '@',
				code: '@',
				input: '@',
				color: '=',
				ngModel: '=',
				toggleItems: '@',
				toggleClasses: '@'
			},
			templateUrl:'resizable.html',
			link: function(scope,elem,attrs){
				scope.elemWidth = elem.find('.resizable').width();
				scope.elemHeight = elem.find('.resizable').height();

		    	scope.$on("angular-resizable.resizing", function (event, args) {
					if(args.width){
						scope.elemWidth = args.width;
					}
					if(args.height){
						scope.elemHeight = args.height;
					}
		        });

				if(scope.toggleItems){
					var items = eval(scope.toggleItems);

					angular.forEach(items, function(key){
						elem.find('.toggle-elem').append('<button target="'+key+'">'+key);
					});

					elem.find('button').on('click',function(){
						var target = elem.find($(this).attr('target'));
						var val = 0;
						if($(target).css('opacity') == 0){
							val = 1;
						}

						$(target).animate({opacity:val},300);
					})
				}

				if(scope.toggleClasses){
					var items = eval(scope.toggleClasses);

					angular.forEach(items, function(key){
						elem.find('.toggle-elem').append('<button>'+key);
					});

					elem.find('button').on('click',function(){
						if(elem.find($(this).text()).length){
							scope.ngModel.class = '';
						}else{						
							var className = $(this).text().replace('.','');
							scope.ngModel.class = className;
						}
					})
				}

				if(scope.input){
					scope.ngModel = {
							copy: "Lorem Ipsum"
						};
				}

				scope.openModal = function(){
					$rootScope.modalTitle = scope.title;
					$rootScope.codeSnippet = scope.code
						.replace('dropdown-class=""','')
						.replace('dropdown-class="orange"','');
					$('#modal-info').modal();
				}

				scope.cloneWindow = function(){
					var cloned = elem.find('ng-transclude').html();
					$('.cloned-window .clone-item:not(:first-child)').remove();
					$('.cloned-window .clone-item').html(cloned);
					$('.cloned-window').addClass('open');
				}

			}
		}
	}])
}());