/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('modules.forms')
    .component('formComponentSelect', {
      bindings: {
        config: '<compConfig',
        hideLabel: '<compHideLabel',
        label: '@compLabel',
        options: '<compOptions',
        placeholder: '@compPlaceholder',
        required: '<compRequired',
        onUpdate: '&'
      },
      template: '' +
          '<label ng-if="! $ctrl.hideLabel" for="<% $ctrl.id %>" ng-class="{ required: $ctrl.required, error: $ctrl.error }">' +
              '<% $ctrl.label %>' +
              '<span ng-if="$ctrl.required" class="required">*</span>' +
          '</label>' +
          '<select ng-attr-id="<% $ctrl.id %>"' +
              'ng-attr-name="<% $ctrl.name %>"' +
              'class="form-control"' +
              'ng-model="$ctrl.value"' +
              'ng-options="option.id as option.name for option in $ctrl.options"' +
              'ng-change="$ctrl.onUpdate({value: $ctrl.value})"' +
              'ng-required="$ctrl.required"' +
              '<option value=""><% $ctrl.placeholder %></option>' +
          '</select>',
      controller: ['FormComponentService', function(FormComponentService) {

        this.error = false;

        this.$onChanges = function(changes) {
          if (changes.config && changes.config.isFirstChange()) {
            return;
          }

          this.initComponent();
        };

        this.$doCheck = function() {
          if (this.config && this.config.formElement.$submitted) {
            this.error = ! isValid(this.config.formElement[this.name].$error);
          }
        };

        this.initComponent = function() {
          this.id          = FormComponentService.spinalCase(this.config.prefix + '_' + this.label);
          this.name        = FormComponentService.snakeCase(this.label);
          this.type        = FormComponentService.defineAttr(this.type, 'text');
          this.required    = FormComponentService.defineAttr(this.required, true);
          this.hideLabel   = FormComponentService.defineAttr(this.hideLabel, false);
          this.init        = true;
          this.setPlaceholder();
        };

        this.setPlaceholder = function() {
          if (typeof this.placeholder === 'undefined') {
            this.placeholder = FormComponentService.getPlaceholder(
              this.config.textPlaceholders,
              this.label,
              this.required
            );
          }
        };

        var isValid = function(obj) {
          if (obj.hasOwnProperty('required')) {
            return false;
          }

          return true;
        };

      }]
    });

})();