(function(){
	angular.module('app.generalOffice')
		.controller('generalOfficeCtrl',['$scope', 'slickProtoService',
			function($scope, slickProtoService){

        $scope.slickConfig = new slickProtoService('white');
        $scope.slickConfig.autoplay = true;
        $scope.slickConfig.autoplaySpeed = 7000;
        $scope.slickConfig.dots = false;

        $scope.portfolios = [
          {
            title: 'Brisbane Multi-Tenant Office Building',
            url: '/portfolio/brisbane-ca',
            image: '/images/site/general-office/brisbane.jpg'
          },
          {
            title: 'San Mateo Office Building',
            url: '/portfolio/san-mateo',
            image: '/images/site/general-office/san-mateo.jpg'
          },
          {
            title: 'Sutter Square',
            url: '/portfolio/concord-ca',
            image: '/images/site/general-office/concord.jpg'
          },
          {
            title: 'The Atrium',
            url: '/portfolio/pleasanton-ca',
            image: '/images/site/general-office/pleasanton.jpg'
          },
          {
            title: 'MMC Palo Alto Office',
            url: '/portfolio/palo-alto-ca',
            image: '/images/site/general-office/palo-alto.jpg'
          }
        ];

	}]);
}());
