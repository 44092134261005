(function(){
	'use strict';

	angular.module('app.common')
	.controller('modalCtrl', ['$scope', '$uibModal', '$rootScope', function($scope, $uibModal, $rootScope){
      this.open = function($elem, $size, $class) {

        var uibModalInstance = $uibModal.open({
          templateUrl: $elem,
          controller: 'modalInstance',
          animation: true,
          scope: $scope,
          size: $size,
          appendTo: $('body')
        });

         uibModalInstance.result.then(function(){
          //
         }, function(){
          //
         });
      };

      this.setBio = function(e){
          var elem = $(e.currentTarget);

          var bio = {
            bio : elem.data('bio'),
            img : elem.data('img'),
            linkedin : elem.data('linkedin'),
            vcard : elem.data('vcard'),
            name : elem.find('.name').text(),
            title : elem.find('.title').text()
          }

          $rootScope.modalBio = bio;
      }

      this.setSlide = function(num){
        $rootScope.modalSlideIndex = num;
      }
	}])
  .controller('modalInstance', ['$scope', '$uibModalInstance',
    function($scope, $uibModalInstance) {

      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.setModalClass = function(name){
        $('.modal').addClass(name);
        $('.top-get-updates').clone().appendTo('.modal .container');
      }

  }]);
}());