(function(){
	'use strict';

	angular.module('app.common')
	.service('slickProtoService', ['$rootScope', function($rootScope){

		return function settings(navColor){	

			var startingSlide = $rootScope.modalSlideIndex ? $rootScope.modalSlideIndex : 0;

			this.infinite = true;
			this.enabled = true;
			this.autoplay = false;
			this.draggable = true;
			this.speed = 1000;
			this.initialSlide = startingSlide;
			this.adaptiveHeight = true;
			this.autoplaySpeed = 0;
			this.slidesToShow = 1;
		    this.dots = true;
		    this.adaptiveHeight = true;
			this.prevArrow = slickArrows('prev', navColor);
			this.nextArrow = slickArrows('next', navColor);
			this.method = {};
			this.event = {
        beforeChange: function (event, slick, currentSlide, nextSlide) {
          var youtubeIframe = $('iframe', slick.$slides[currentSlide])[0];
          var youtubeFunc = 'pauseVideo';

          if (youtubeIframe !== undefined) {
            youtubeIframe.contentWindow.postMessage('{"event":"command","func":"' + youtubeFunc + '","args":""}', '*');
          }
        }
			};

			function slickArrows(dir,color){
				return '<div class="slick-'+dir+'">'+
						'<img src="/images/site/global/icon-box-arrow-'+color+'.svg">'+
					   '</div>';
			}
			
		}
		
	}])
}());