/*jslint white: true, nomen: true */
/*global angular */
(function() {
    'use strict';

    angular.module('app.connectWithUs')
        .controller('connectWithUsCtrl', ['$scope',
            function($scope) {

                // $scope.filter = 'all';
                $scope.filter = 'twitter';

                $scope.filterTrigger = function(elem){
                    $scope.filter = elem;

                    var filter = $('a[data-filter=".dcsns-'+elem+'"]');
                    if(elem == 'all'){
                        filter = $('a[data-filter="*"]');
                    }
                    filter.click();
                }
                
            }
        ])
}());
